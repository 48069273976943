import request from "@/utils/request";

// 新增部门
export function addDept(data) {
  return request("post", "/apm/dept/add", data);
}
// 删除部门
export function deleteDept(data) {
  return request("post", "/apm/dept/del", data);
}
// 修改部门
export function updateDept(data) {
  return request("post", "/apm/dept/upd", data);
}
// 查询部门
export function queryDept(data) {
  return request("post", "/apm/dept/getDeptTree", data);
}
// 查询当前用户部门权限
export function queryDeptAuth(data) {
  return request("post", "/apa/api/dept/getCurrentUserDeptTree", data);
}
// 根据所选部门查询门店角色
export function queryRoleByDept(data) {
  return request("post", "/apa/role/getShopListRole", data);
}